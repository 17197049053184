// @ts-strict-ignore
import { LOCALE_KO, LocaleType } from './i18n/config'
import { CDN_URL } from './vars'

// FOOTER
export const IMG_FOOTER_YOUTUBE = `${CDN_URL}/web/event/ic-youtubue-dark%403x.png`
export const IMG_FOOTER_INSTGRAM = `${CDN_URL}/web/new_landing/btn-instagram%403x.png`
export const IMG_FOOTER_FACEBOOK = `${CDN_URL}/web/new_landing/btn-facebook%403x.png`
export const IMG_FOOTER_NAVER_BLOG = `${CDN_URL}/web/new_landing/btn-naver-blog%403x.png`

// 튜터 스타 이미지

export const IMG_STRIKE_SMALL = `${CDN_URL}/web/tutorpage/tutor_new_icons/ic-flag-red.svg`

export const IMG_STAR_CONTAINED_PRIMARY = `${CDN_URL}/new-theme/icon/star_contained/star_contained_primary.png`
export const IMG_STAR_CONTAINED_EMPTY = `${CDN_URL}/new-theme/icon/star_contained/star_contained_empty.png`
export const IMG_CIRCLE_20_MIN = `${CDN_URL}/web/icons/20min.png`
export const IMG_CIRCLE_40_MIN = `${CDN_URL}/web/icons/40min.png`

export const IMG_GNB_APP = (locale) => `${CDN_URL}/web/promotion/20220204-app-${locale}.png`

export const INVITE_HERO_IMAGE = `${CDN_URL}/web/202209/ic-invite-hero.png`

// Welcome Coupon Images

export const IMG_WELCOME_COUPON_CARD_DESKTOP = (locale) =>
  `${CDN_URL}/web/promotion/202301/3cards_welcome_0202_${locale}.png`
export const IMG_WELCOME_COUPON_CARD_MOBILE = (locale) =>
  `${CDN_URL}/web/promotion/202301/moWeb_welcome_0202_${locale}.png`
export const IMG_WELCOME_CHALLENGE_CARD_MOBILE = (locale) =>
  `${CDN_URL}/web/promotion/202301/moWeb_welcome_${locale}.png`

export const IMG_WELCOME_COUPON = (locale) => `${CDN_URL}/web/202208/new-welcome-popup-${locale}.png`

export const IMG_WELCOME_COUPON_POPUP_IMG = (locale: LocaleType, isMobile: boolean) => {
  return isMobile
    ? `${CDN_URL}/web/promotion/202302/new_user_welcome_coupon_moWeb_${locale}.webp`
    : `${CDN_URL}/web/promotion/202302/new_user_welcome_coupon_desktop_${locale}.webp`
}

export const IMG_INIVTE_EVENT_POPUP = (locale) => `${CDN_URL}/web/202208/Invite-popup-${locale}.png`
export const IMG_EARLY_BIRD_EVENT_POPUP = (locale) =>
  `${CDN_URL}/web/promotion/202212/early-popup-before-7-${locale}.png`
export const IMG_NEWCOMER_EVENT_POPUP = (locale) =>
  `${CDN_URL}/web/promotion/202301/promotion-popup-24-hour-${locale}.png`
export const IMG_TRIAL_EVENT_POPUP = `${CDN_URL}/web/202208/trial-event-popup-ko.png`

export const IMG_REPURCHASE_PACK_COUPON = (locale) =>
  `${CDN_URL}/web/promotion/img_repurchase_pack_coupon_${locale}.png`

export const IMG_BOSTON_CHALLENGE_POPUP = (locale) =>
  `${CDN_URL}/web/promotion/202302/boston-challenge-popup-${locale === LOCALE_KO ? 'ko' : 'en'}.png`

export const IMG_STARBUCKS_EVENT_2023_02_POPUP = () => `${CDN_URL}/web/promotion/202302/starbucks_event_2023_02.webp`

export const IMG_PAYPAL_NO_BORDER = `${CDN_URL}/web/tutorpage/tutor_new_icons/paypal_no_border.png`
export const IMG_SENTBE = `${CDN_URL}/web/tutorpage/tutor_new_icons/sentbe.png`
export const IMG_PAYPAL_NO_BORDER_3X = `${CDN_URL}/web/tutorpage/tutor_new_icons/paypal_no_border_3x.png`

//WEBINAR VIDEO PLAYER
export const IC_VIDEO_10_BEFORE = `${CDN_URL}/icon/icon-48-px-10-sec-before@3x.png`
export const IC_VIDEO_10_AFTER = `${CDN_URL}/icon/icon-32-px-10-sec-after@3x.png`
export const IC_VIDEO_PAUSE = `${CDN_URL}/icon/icon-32-px-pause-with-b-space@3x.png`
export const IC_VIDEO_FULL_SCREEN = `${CDN_URL}/icon/icon-24-px-full-screen@3x.png`
export const IC_VIDEO_SMALL_SCREEN = `${CDN_URL}/icon/icon-32-px-small-screen@3x.png`

export const IC_STAR_GRAY_LIGHT = `${CDN_URL}/new-theme/new-icons/adult/icon/star/small/gray-light.png`

export const IC_LEVEL_UP = `${CDN_URL}/web/tutor/ic-level-up.png`

export const IC_PURCHASE_GLOBAL_ACTIVE = `${CDN_URL}/icon/global_active.svg`
export const IC_PURCHASE_GLOBAL_INACTIVE = `${CDN_URL}/icon/global_inactive.svg`
export const IC_PURCHASE_PAYPAL = `${CDN_URL}/icon/paypal_logo.svg`
export const IC_PURCHASE_GLOBAL_CARD = `${CDN_URL}/icon/global_card.svg`
export const IMG_PURCHASE_LOADING = `${CDN_URL}/web/global_payment_loading.gif`

// 이 윗줄에 추가해주세요.
// -----------------------------------------------------------------
// 아래는 자동 생성됨

export const IMG_B2B_IMG_B2B1 = `${CDN_URL}/web/g/b2b/img_B2B1.png`
export const IMG_B2B_IMG_B2B1_EN = `${CDN_URL}/web/g/b2b/img_B2B1_en.png`

export const IMG_JUNIOR = `${CDN_URL}/web/img-junior.png`
export const IMG_CASE_CUSTOMER_BEGINNER_PIC_BEGINNER1 = `${CDN_URL}/web/g/case/customer/beginner/pic_beginner1.png`
export const IMG_CASE_CUSTOMER_BEGINNER_PIC_BEGINNER2 = `${CDN_URL}/web/g/case/customer/beginner/pic_beginner2.png`
export const IMG_CASE_CUSTOMER_BEGINNER_PIC_BEGINNER3 = `${CDN_URL}/web/g/case/customer/beginner/pic_beginner3.png`
export const IMG_CASE_CUSTOMER_BEGINNER_PIC_BEGINNER4 = `${CDN_URL}/web/g/case/customer/beginner/pic_beginner4.png`
export const IMG_CASE_CUSTOMER_JUNIOR_PIC_JUNIOR1 = `${CDN_URL}/web/g/case/customer/junior/pic_junior1.png`
export const IMG_CASE_CUSTOMER_JUNIOR_PIC_JUNIOR2 = `${CDN_URL}/web/g/case/customer/junior/pic_junior2.png`
export const IMG_CASE_CUSTOMER_JUNIOR_PIC_JUNIOR3 = `${CDN_URL}/web/g/case/customer/junior/pic_junior3.png`
export const IMG_CASE_CUSTOMER_JUNIOR_PIC_JUNIOR4 = `${CDN_URL}/web/g/case/customer/junior/pic_junior4.png`
export const IMG_CASE_CUSTOMER_LIVED_ABROAD_PIC_LIVED1 = `${CDN_URL}/web/g/case/customer/lived-abroad/pic_lived1.png`
export const IMG_CASE_CUSTOMER_LIVED_ABROAD_PIC_LIVED2 = `${CDN_URL}/web/g/case/customer/lived-abroad/pic_lived2.png`
export const IMG_CASE_CUSTOMER_LIVED_ABROAD_PIC_LIVED3 = `${CDN_URL}/web/g/case/customer/lived-abroad/pic_lived3.png`
export const IMG_CASE_CUSTOMER_LIVED_ABROAD_PIC_LIVED4 = `${CDN_URL}/web/g/case/customer/lived-abroad/pic_lived4.png`
export const IMG_CASE_CUSTOMER_LIVE_ABROAD_PIC_LIVE1 = `${CDN_URL}/web/g/case/customer/live-abroad/pic_live1.png`
export const IMG_CASE_CUSTOMER_LIVE_ABROAD_PIC_LIVE2 = `${CDN_URL}/web/g/case/customer/live-abroad/pic_live2.png`
export const IMG_CASE_CUSTOMER_LIVE_ABROAD_PIC_LIVE3 = `${CDN_URL}/web/g/case/customer/live-abroad/pic_live3.png`
export const IMG_CASE_CUSTOMER_LIVE_ABROAD_PIC_LIVE4 = `${CDN_URL}/web/g/case/customer/live-abroad/pic_live4.png`

export const IMG_CASE_CUSTOMER_PROFESSIONAL_PIC_PRO1 = `${CDN_URL}/web/g/case/customer/professional/pic_pro1.png`
export const IMG_CASE_CUSTOMER_PROFESSIONAL_PIC_PRO2 = `${CDN_URL}/web/g/case/customer/professional/pic_pro2.png`
export const IMG_CASE_CUSTOMER_PROFESSIONAL_PIC_PRO3 = `${CDN_URL}/web/g/case/customer/professional/pic_pro3.png`
export const IMG_CASE_CUSTOMER_PROFESSIONAL_PIC_PRO4 = `${CDN_URL}/web/g/case/customer/professional/pic_pro4.png`
export const IMG_CASE_PURPOSE_BUSINESS_PIC_BUSINESS1 = `${CDN_URL}/web/g/case/purpose/business/pic_business1.png`
export const IMG_CASE_PURPOSE_BUSINESS_PIC_BUSINESS2 = `${CDN_URL}/web/g/case/purpose/business/pic_business2.png`
export const IMG_CASE_PURPOSE_BUSINESS_PIC_BUSINESS3 = `${CDN_URL}/web/g/case/purpose/business/pic_business3.png`
export const IMG_CASE_PURPOSE_BUSINESS_PIC_BUSINESS4 = `${CDN_URL}/web/g/case/purpose/business/pic_business4.png`
export const IMG_CASE_PURPOSE_CORRECTION_PIC_CORRECTION1 = `${CDN_URL}/web/g/case/purpose/correction/pic_correction1.png`
export const IMG_CASE_PURPOSE_CORRECTION_PIC_CORRECTION2 = `${CDN_URL}/web/g/case/purpose/correction/pic_correction2.png`
export const IMG_CASE_PURPOSE_CORRECTION_PIC_CORRECTION3 = `${CDN_URL}/web/g/case/purpose/correction/pic_correction3.png`
export const IMG_CASE_PURPOSE_CORRECTION_PIC_CORRECTION4 = `${CDN_URL}/web/g/case/purpose/correction/pic_correction4.png`
export const IMG_CASE_PURPOSE_EXAM_PIC_EXAM1 = `${CDN_URL}/web/g/case/purpose/exam/pic_exam1.png`
export const IMG_CASE_PURPOSE_EXAM_PIC_EXAM2 = `${CDN_URL}/web/g/case/purpose/exam/pic_exam2.png`
export const IMG_CASE_PURPOSE_EXAM_PIC_EXAM3 = `${CDN_URL}/web/g/case/purpose/exam/pic_exam3.png`
export const IMG_CASE_PURPOSE_EXAM_PIC_EXAM4 = `${CDN_URL}/web/g/case/purpose/exam/pic_exam4.png`
export const IMG_CASE_PURPOSE_INTERVIEW_PIC_INTERVIEW1 = `${CDN_URL}/web/g/case/purpose/interview/pic_interview1.png`
export const IMG_CASE_PURPOSE_INTERVIEW_PIC_INTERVIEW2 = `${CDN_URL}/web/g/case/purpose/interview/pic_interview2.png`
export const IMG_CASE_PURPOSE_INTERVIEW_PIC_INTERVIEW3 = `${CDN_URL}/web/g/case/purpose/interview/pic_interview3.png`
export const IMG_CASE_PURPOSE_INTERVIEW_PIC_INTERVIEW4 = `${CDN_URL}/web/g/case/purpose/interview/pic_interview4.png`
export const IMG_CASE_PURPOSE_PRONUNCIATION_PIC_PRONUNCIATION1 = `${CDN_URL}/web/g/case/purpose/pronunciation/pic_pronunciation1.png`
export const IMG_CASE_PURPOSE_PRONUNCIATION_PIC_PRONUNCIATION2 = `${CDN_URL}/web/g/case/purpose/pronunciation/pic_pronunciation2.png`
export const IMG_CASE_PURPOSE_PRONUNCIATION_PIC_PRONUNCIATION3 = `${CDN_URL}/web/g/case/purpose/pronunciation/pic_pronunciation3.png`
export const IMG_CASE_PURPOSE_PRONUNCIATION_PIC_PRONUNCIATION4 = `${CDN_URL}/web/g/case/purpose/pronunciation/pic_pronunciation4.png`

export const IMG_HOME_IMG_HOME1 = `${CDN_URL}/web/g/home/img_home1.png`
export const IMG_HOME_IMG_HOME1_EN = `${CDN_URL}/web/g/home/img_home1_en.png`
export const IMG_HOME_IMG_HOME2 = `${CDN_URL}/web/g/home/img_home2.png`
export const IMG_HOME_IMG_HOME2_EN = `${CDN_URL}/web/g/home/img_home2_en.png`
export const IMG_HOME_IMG_HOME3 = `${CDN_URL}/web/g/home/img_home3.png`
export const IMG_HOME_IMG_HOME3_EN = `${CDN_URL}/web/g/home/img_home3_en.png`
export const IMG_HOME_IMG_HOME4 = `${CDN_URL}/web/g/home/img_home4.png`
export const IMG_HOME_IMG_HOME4_EN = `${CDN_URL}/web/g/home/img_home4_en.png`

export const IMG_HOME_PIC_HOME1 = `${CDN_URL}/web/g/home/pic_home1@3x.jpg`
export const IMG_HOME_PIC_HOME2 = `${CDN_URL}/web/g/home/pic_home2@3x.jpg`
export const IMG_HOME_PIC_HOME3 = `${CDN_URL}/web/g/home/pic_home3@3x.jpg`
export const IMG_HOME_PIC_HOME4 = `${CDN_URL}/web/g/home/pic_home4@3x.jpg`
export const IMG_HOME_PIC_HOME5 = `${CDN_URL}/web/g/home/pic_home5@3x.jpg`
export const IMG_HOME_PIC_HOME6 = `${CDN_URL}/web/g/home/pic_home6@3x.jpg`
export const IMG_HOME_PIC_HOME7 = `${CDN_URL}/web/g/home/pic_home7@3x.jpg`
export const IMG_HOME_PIC_HOME8 = `${CDN_URL}/web/g/home/pic_home8@3x.jpg`
export const IMG_HOME_PIC_HOME9 = `${CDN_URL}/web/g/home/business-english@3x.jpg`
export const IMG_HOME_PIC_HOME10 = `${CDN_URL}/web/g/home/beginner@3x.jpg`

export const IMG_TUTORS_IMG_TUTOR1 = `${CDN_URL}/web/g/tutors/img_tutor1.png`

// 친구 초대 & 시크릿 오퍼

// 가격 페이지

export const IMG_GUARANTEE_1 = `${CDN_URL}/web/pricing/guarantee_1.png`
export const IMG_GUARANTEE_2 = `${CDN_URL}/web/pricing/guarantee_2.png`
export const IMG_GUARANTEE_3 = `${CDN_URL}/web/pricing/guarantee_3.png`
export const IMG_GUARANTEE_APP_1 = `${CDN_URL}/web/pricing/app_guarantee_1.png`
export const IMG_GUARANTEE_APP_2 = `${CDN_URL}/web/pricing/app_guarantee_2.png`
export const IMG_GUARANTEE_APP_3 = `${CDN_URL}/web/pricing/app_guarantee_3.png`

export const IMG_BENEFIT_1 = `${CDN_URL}/web/pricing/benefit_1.png`
export const IMG_BENEFIT_2 = `${CDN_URL}/web/pricing/benefit_2.png`
export const IMG_BENEFIT_APP_1 = `${CDN_URL}/web/pricing/app_benefit_1.png`
export const IMG_BENEFIT_APP_2 = `${CDN_URL}/web/pricing/app_benefit_2.png`

// Invite

export const IMG_INVITE_POINT_CARD = `${CDN_URL}/web/invite/bg-invite-point-card.png`

// export const IMG_INVITE_CONTENT_EN = `${CDN_URL}/web/promotion/20211223/211223-invite-landing-en.png`

export const IMG_INVITE_COUPON_EN = `${CDN_URL}/web/promotion/home-referral-banner-v1-en.png`
export const IMG_INVITE_COUPON_KO = `${CDN_URL}/web/promotion/home-referral-banner-ko.png`

export const IMG_INVITE_COUPON_V2 = (locale) => `${CDN_URL}/web/invite/202301/invite-card-${locale}.png`
export const IMG_INVITE_COUPON_SPECIAL_V2 = (locale) => `${CDN_URL}/web/invite/202301/invite-card-special-${locale}.png`
export const IMG_INVITE_COUPON_V2_EN = `${CDN_URL}/web/invite/202301/invite-card-en.png`
export const IMG_INVITE_COUPON_V2_KO = `${CDN_URL}/web/invite/202301/invite-card-ko.png`

export const IMG_OG_INVITE_EN = `${CDN_URL}/web/promotion/20211223/20211223-og-invite-en.png`
export const IMG_OG_INVITE_KO = `${CDN_URL}/web/promotion/20211223/20211223-og-invite-ko.png`

// newsletter

export const IMG_NEWSLETTER_TITLE = `${CDN_URL}/contents/newsletter/header.png`

// K-voucher

export const IMG_K_VOUCHER_MAIN_WEB = `${CDN_URL}/web/voucher/220603_voucher_web.png`
export const IMG_K_VOUCHER_MAIN_MOBILE = `${CDN_URL}/web/voucher/220603_voucher_mobile.png`
export const IMG_RINGLE_X_VOUCHER = `${CDN_URL}/web/voucher/ringle_x_voucher.png`
export const IC_CHEVRON_DOWN = `${CDN_URL}/web/k-voucher/06-icons-outlined-2-px-chevron-down%403x.png`

export const IMG_K_VOUCHER_CASE_1 = `${CDN_URL}/web/k-voucher/bitmap-1%403x.jpg`
export const IMG_K_VOUCHER_CASE_2 = `${CDN_URL}/web/k-voucher/case-2%403x.jpg`
export const IMG_K_VOUCHER_CASE_3 = `${CDN_URL}/web/k-voucher/case-3%403x.jpg`

// Tutor

export const IMG_NO_VIDEO = `${CDN_URL}/web/profile/img-video-defaul.svg`

export const IMG_TUTOR_PEAKHOUR_POPUP = `${CDN_URL}/new-theme/tutor/peakhour_slot_popup_img.png`

export const IC_TEENS_BOOK = `${CDN_URL}/web/teens/book-black.svg`

// Nov Event

// Webinar
export const IMG_WEBINAR_BUSINESS = `${CDN_URL}/web/webinar/pic_webinar_business.jpeg`
export const IMG_WEBINAR_DEFAULT = `${CDN_URL}/web/webinar/pic_webinar_default.png`
export const IMG_WEBINAR_GRAMMAR = `${CDN_URL}/web/webinar/pic_webinar_grammar.jpeg`
export const IMG_WEBINAR_RINGLE = `${CDN_URL}/web/webinar/pic_webinar_ringle.png`
export const IMG_WEBINAR_TREND = (locale) => `${CDN_URL}/web/webinar/webinar_trend_banner_${locale}.png`

export const IC_SUBTITLE_OFF = `${CDN_URL}/web/webinar/subtitle_off.svg`
export const IC_SUBTITLE_ON = `${CDN_URL}/web/webinar/subtitle_on.svg`

// Notice
export const IMG_ZOOM_NOTICE = (locale) => `${CDN_URL}/notice/zoom_notice_${locale}.png`

// 웹앱 개편

export const IC_UNCHECK_CIRCLE = `${CDN_URL}/web/icon-check-circle-incomplete.svg`
export const IC_CHECK_CIRCLE = `${CDN_URL}/web/icon-check-circle-complete.svg`

export const IMG_POINT_CARD = `${CDN_URL}/web/point-card.png`
export const IMG_POINT_CARD_2 = `${CDN_URL}/web/point-card-2.png`

// 피드백레포트 개편
export const IC_PRINTER_PRIMARY = `${CDN_URL}/web/icon-printer-primary.svg`
export const IC_STAR_OUTLINED = `${CDN_URL}/web/icon-star-outlined.svg`
export const IC_STAR_PRIMARY = `${CDN_URL}/web/icon-star-primary.svg`
export const RINGLE_DOC_ARROW = `https://d38emex6h5e12i.cloudfront.net/ringledoc/icon-feedback-arrow.png`

// 2021년 1월 튜터리퍼럴

//2020년 연말 프로모션

export const IMG_ZOOM_GUIDE2 = (index, locale) => `${CDN_URL}/web/zoom/guide2_${index}_${locale}.png`

// Welcome Week

// 학생 프로필

// Giving back

// 신규 - 웹

// 신규 - 모바일

// 기존 - 웹

// 기존 - 모바일

// Giving Back invite

// 2021년 5월 튜터리퍼럴

export const IC_HOW_IT_WORKS_1 = `${CDN_URL}/web/icons/landing-how-it-works-1.svg`
export const IC_HOW_IT_WORKS_2 = `${CDN_URL}/web/icons/landing/howto2.svg`
export const IC_HOW_IT_WORKS_3 = `${CDN_URL}/web/icons/landing/howto3.svg`

export const IMG_COURSE_INS = (locale, isMobile) =>
  `${CDN_URL}/web/landing/material/${locale}/${isMobile ? 'mobile' : 'web'}/img_course_insight.png`
export const IMG_COURSE_EXP = (locale, isMobile) =>
  `${CDN_URL}/web/landing/material/${locale}/${isMobile ? 'mobile' : 'web'}/img_course_expression.png`
export const IMG_COURSE_QUE = (locale, isMobile) =>
  `${CDN_URL}/web/landing/material/${locale}/${isMobile ? 'mobile' : 'web'}/img_course_question.png`
export const IMG_COURSE_VID = (locale, isMobile) =>
  `${CDN_URL}/web/landing/material/${locale}/${isMobile ? 'mobile' : 'web'}/img_course_videoandarticle.png`

// ------랜딩 개편-------
// 로그인 Social login icons

// ICONS & LOGOS

export const RINGLE_LOGO_W_TEXT = `${CDN_URL}/web/logo/icon-logo-ringle-long-copy.svg`

export const RINGLE_LOGO_W_TEXT_SM = `${CDN_URL}/new-theme/icon/pricing/icon-logo-ringle.svg`
export const RINGLE_LOGO_ROUND = `/assets/img/logo/logo_purple.svg`

export const IC_CHEVRON_LEFT_WHITE_XL = `${CDN_URL}/web/icons/icon-chevron-left-bold-white-x-large.svg`
export const IC_CHEVRON_RIGHT_WHITE_XL = `${CDN_URL}/web/icons/icon-chevron-right-bold-white-x-large.svg`
export const IC_THUMB_UP_GRAY = `${CDN_URL}/web/icons/icon-thumbup-gray.svg`
export const IC_THUMB_UP_PRIMARY = `${CDN_URL}/web/icons/icon-thumbup-purple.svg`

// HOW IT WORKS
export const IMG_WEB_PAST_1 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/past-1.png`
export const IMG_WEB_PAST_2 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/past-2.png`
export const IMG_WEB_PAST_3 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/past-3.png`
export const IMG_WEB_PAST_4 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/past-4.png`

export const IMG_WEB_STEP_1 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-1-web.png`
export const IMG_WEB_STEP_2 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-2-web.png`
export const IMG_WEB_STEP_3 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-3-web.png`
export const IMG_WEB_STEP_4 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-4-web.png`
export const IMG_WEB_STEP_5 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-5-mobile.png`
export const IMG_WEB_STEP_6 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-6-web.png`
export const IMG_WEB_STEP_7 = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-7-web.png`

export const IMG_WEB_STEP_1_M = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-1-mobile.png`
export const IMG_WEB_STEP_2_M = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-2-mobile.png`
export const IMG_WEB_STEP_3_M = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-3-mobile.png`
export const IMG_WEB_STEP_4_M = (locale) => `${CDN_URL}/web/landing/how-it-works/${locale}/step-4-mobile.png`

// LANDING HERO IMAGES
export const HERO_HOME = (viewport) => `${CDN_URL}/web/landing/home/main_${viewport}.png`
export const HERO_HOME_B = (viewport) => `${CDN_URL}/web/landing/home_b/main_${viewport}.png`

// B2B
export const HERO_B2B = (viewport) => `${CDN_URL}/web/landing/b2b/b2b_${viewport}.png`

export const HERO_ESSAY = (viewport) => `${CDN_URL}/web/landing/essay/essay_${viewport}.png`
export const HERO_HOWTO = (viewport) => `${CDN_URL}/web/landing/howto/how_${viewport}.png`
export const HERO_REVIEW = (viewport) => `${CDN_URL}/web/landing/review/review_${viewport}.png`

export const HERO_MATERIAL = (viewport, locale) => `${CDN_URL}/web/landing/material/${locale}/book_${viewport}.png`
export const HERO_MATERIAL_BOOK = (viewport) => `${CDN_URL}/web/landing/material/material_${viewport}.png`
export const HERO_TUTOR = (viewport) => `${CDN_URL}/web/landing/tutor/tutor_${viewport}.png`

export const HERO_SYSTEM = (viewport, locale) => `${CDN_URL}/web/landing/system/${locale}/system_${viewport}.png`

export const IMG_SYSTEM_1 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_1.png`
export const IMG_SYSTEM_2 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_2.png`
export const IMG_SYSTEM_3 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_3.png`
export const IMG_SYSTEM_4 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_4.png`
export const IMG_SYSTEM_5 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_5.png`
export const IMG_SYSTEM_6 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_6.png`
export const IMG_SYSTEM_7 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_7.png`
export const IMG_SYSTEM_8 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_8.png`
export const IMG_SYSTEM_9 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_9.png`
export const IMG_SYSTEM_10 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_10.png`
export const IMG_SYSTEM_11 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_11.png`
export const IMG_SYSTEM_12 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_12.png`
export const IMG_SYSTEM_13 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_13.png`
export const IMG_SYSTEM_14 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_14.png`
export const IMG_SYSTEM_15 = (locale) => `${CDN_URL}/web/landing/system/${locale}/system_15.png`

// ICONS

export const IC_B2B1 = `${CDN_URL}/web/icons/landing/group-26.svg`
export const IC_B2B2 = `${CDN_URL}/web/icons/landing/group-21.svg`
export const IC_B2B3 = `${CDN_URL}/web/icons/landing/group-bell.svg`

export const IC_ESSAY1 = `${CDN_URL}/web/icons/landing/essay1.svg`
export const IC_ESSAY2 = `${CDN_URL}/web/icons/landing/essay2.svg`
export const IC_ESSAY3 = `${CDN_URL}/web/icons/landing/essay3.svg`
export const IC_ESSAY4 = `${CDN_URL}/web/icons/landing/essay4.svg`

export const IMG_SAMPLE_WIDE = `${CDN_URL}/web/landing/cover-8.jpg`

export const IMG_REFERRAL_RECORD_KO = `${CDN_URL}/web/invite/referral_record_ko.png`
export const IMG_REFERRAL_RECORD_EN = `${CDN_URL}/web/invite/referral_record_en.png`

export const IMG_ZOOM_GUIDE_CLASSROOM = `${CDN_URL}/new-theme/classroom/img-zoom-guide.png`
export const IMG_POPUP_GUIDE_CLASSROOM_KO = `${CDN_URL}/new-theme/classroom/img_how_to_turn_on_pop_up_kr__.png`
export const IMG_POPUP_GUIDE_CLASSROOM_EN = `${CDN_URL}/new-theme/classroom/img_how_to_turn_on_pop_up_en__.png`
export const IMG_ZOOM_GUIDE_CLASSROOM_KR = `${CDN_URL}/new-theme/classroom/image_kr-min.jpg`
export const IMG_ZOOM_GUIDE_CLASSROOM_EN = `${CDN_URL}/new-theme/classroom/image_en-min.jpg`

// Team 소개 페이지
export const OFFICE_SANMATEO = `${CDN_URL}/web/team/sanmateo-office.jpg`
export const TEAM_MAIN = `${CDN_URL}/web/team/main2.png`
export const TEAM_G = `${CDN_URL}/web/team/g.png`
export const TEAM_R = `${CDN_URL}/web/team/r.png`
export const TEAM_E = `${CDN_URL}/web/team/e.png`
export const TEAM_A = `${CDN_URL}/web/team/a.png`
export const TEAM_T = `${CDN_URL}/web/team/t.png`
export const OFFICE_SEOUL = `${CDN_URL}/web/team/office-2@3x.jpg`
export const OFFICE_SEATTLE = `${CDN_URL}/web/team/seattle.png`
export const OFFICE_SYDNET = `${CDN_URL}/web/team/sydney.png`
export const OFFICE_PENNSYLVANIA = `${CDN_URL}/web/team/pennsylvania.png`
export const JOIN_US = `${CDN_URL}/web/team/joinus.png`
export const CULTURE_1 = `${CDN_URL}/web/team/culture-1.png`
export const CULTURE_2 = `${CDN_URL}/web/team/culture-2.png`
export const CULTURE_3 = `${CDN_URL}/web/team/culture-3.png`
export const IC_HOME = `${CDN_URL}/web/team/ic-home.png`

export const ETIQUETTE_1 = `${CDN_URL}/web/etiquette/etiquette_1.png`
export const ETIQUETTE_2 = `${CDN_URL}/web/etiquette/etiquette_2.png`
export const ETIQUETTE_3 = `${CDN_URL}/web/etiquette/etiquette_3.png`
export const ETIQUETTE_4 = `${CDN_URL}/web/etiquette/etiquette_4.png`

export const SWITCH_ACTIVE = `${CDN_URL}/web/switch-active.svg`
export const SWITCH_INACTIVE = `${CDN_URL}/web/switch-inactive.svg`

export const IC_BAR_PRIMARY = `${CDN_URL}/web/bar-primary.png`
export const IC_BAR_GRAY = `${CDN_URL}/web/bar-gray.png`

export const TRIAL_STEP = (locale, isMobile, step) =>
  `${CDN_URL}/new-theme/trial/trial-step-${step}-${isMobile ? 'app' : 'web'}-${locale}.png`

export const IMG_STAMP = `${CDN_URL}/new-theme/new-icons/adult/image/stamp/stamp.png`

export const MEGAPHONE_PURPLE = `${CDN_URL}/new-theme/icon/megaphone-purple.png`

// TEENS

export const IMG_LOGO_WHITE = `${CDN_URL}/new-theme/teens/logo_white_text.svg`

export const IMG_PERSON_SEARCH = `${CDN_URL}/new-theme/teens/person_search.svg`
export const IMG_PERSON_SEARCH_TEENS = `${CDN_URL}/new-theme/teens/person_search_for_teens.svg`

export const TEENS_SEO_IMAGE = `${CDN_URL}/new-theme/teens/ringle_teens_SEO_image.png`

// TOKEN

export const NEW_PROMOTION_POPUP = (locale) => `${CDN_URL}/web/promotion/202301/invite-popup-${locale}.png`
export const TEENS_POPUP_KO = `${CDN_URL}/web/teens/20221119-teens-popup-ko.png`
export const TEENS_POPUP_EN = `${CDN_URL}/web/teens/20221119-teens-popup-en.png`
export const COUPON_TOKEN_PRIMARY = `${CDN_URL}/lesson_book/img-coupon-used%403x.png`
export const COUPON_TOKEN_DISABLED = `${CDN_URL}/lesson_book/img-coupon-not-used%403x.png`
export const COUPON_TOKEN_SUPPLEMENTARY = `${CDN_URL}/lesson_book/img-supplementary-coupon-activated%403x.png`
export const COUPON_TOKEN_SUPPLEMENTARY_DISABLED = `${CDN_URL}/lesson_book/img-supplementary-coupon-deactivated%403x.png`
export const NEW_TUTOR_CONGRATS_IMG = `${CDN_URL}/new-theme/tutor/img-congraturation.png`

//Mobile - Application
export const GIF_CLASSROOM = `${CDN_URL}/web/survey/img_ringledocs_zoom.gif`

//Referral - Sharing Example

//Referral - NewYearIcon

//Referral - 상시
export const ICON_REFERRAL_DEFAULT_BANNER = `${CDN_URL}/web/default_referral/default_banner.png`
export const ICON_REFERRAL_DEFAULT_STEP_1 = `${CDN_URL}/web/default_referral/default_step1.png`
export const ICON_REFERRAL_DEFAULT_STEP_2 = `${CDN_URL}/web/default_referral/default_step2.png`
export const ICON_REFERRAL_DEFAULT_STEP_3 = `${CDN_URL}/2024-image/1719810572204_ICON_REFERRAL_DEFAULT_STEP_3.png`

//Referral 2022 Summer event

export const REFERRAL_EVENT_THANKS_MODAL_BANNER = `${CDN_URL}/new-theme/tutor/summer_event/referral_thanks_modal_top.png`

export const REFERRAL_SHARED_IMAGE_1 = `${CDN_URL}/new-theme/tutor/referaal_shared_image_1.png`
export const REFERRAL_SHARED_IMAGE_2 = `${CDN_URL}/new-theme/tutor/referaal_shared_image_2.png`
export const REFERRAL_SHARED_IMAGE_3 = `${CDN_URL}/new-theme/tutor/referral_shared_5_5_3_changed.png`
export const REFERRAL_SHARED_IMAGE_4 = `${CDN_URL}/new-theme/tutor/referaal_shared_image_4.png`
export const REFERRAL_SHARED_IMAGE_5_5_1 = `${CDN_URL}/new-theme/tutor/referral_shared_5_5_1.png`
export const REFERRAL_SHARED_IMAGE_5_5_2 = `${CDN_URL}/new-theme/tutor/referral_shared_5_5_2.png`
export const REFERRAL_SHARED_IMAGE_5_5_3 = `${CDN_URL}/new-theme/tutor/referaal_shared_image_3_changed.png`
export const REFERRAL_SHARED_IMAGE_5_5_4 = `${CDN_URL}/new-theme/tutor/referral_shared_5_5_4.png`

export const APPLICATION_QUIZ_EXAMPLE = `${CDN_URL}/new-theme/tutor/summer_event/quiz_example.png`
// STUDENT POLICY PDF
export const STUDENT_POLICY = `${CDN_URL}/new-theme/policy/Privacy_Policy_WIP_220103.pdf`
export const STUDENT_TERMS_OF_SERVICE = `${CDN_URL}/new-theme/policy/Terms_of_Service_WIP_220103.pdf`
export const STUDENT_POLICY_KO = `${CDN_URL}/new-theme/policy/Privacy_Policy_KO.docx.pdf`
export const STUDENT_TERMS_OF_SERVICE_KO = `${CDN_URL}/new-theme/policy/Terms_of_Service_KO.pdf`

// Landing Home Images
export const MOCKUP_1 = `${CDN_URL}/new-theme/landing/home/mockup_1.png`
export const MOCKUP_2 = `${CDN_URL}/new-theme/landing/home/mockup_2.png`
export const MOCKUP_3 = `${CDN_URL}/new-theme/landing/home/mockup_3.png`
export const MOCKUP_DESKTOP_1 = `${CDN_URL}/new-theme/landing/home/mockup_desktop_1_new.png`
export const MOCKUP_DESKTOP_2 = `${CDN_URL}/new-theme/landing/home/mockup_desktop_2_new.png`
export const MOCKUP_DESKTOP_3 = `${CDN_URL}/new-theme/landing/home/mockup_desktop_3.png`
export const CUSTOMER_VECTOR_1 = `${CDN_URL}/new-theme/landing/home/customer_vector_1.svg`
export const CUSTOMER_VECTOR_2 = `${CDN_URL}/new-theme/landing/home/customer_vector_2.svg`
export const CUSTOMER_VECTOR_3 = `${CDN_URL}/new-theme/landing/home/customer_vector_3.svg`
export const TESTIMONIAL_1 = `${CDN_URL}/new-theme/landing/home/testimonial_1.png`
export const TESTIMONIAL_2 = `${CDN_URL}/new-theme/landing/home/testimonial_2.png`
export const TESTIMONIAL_3 = `${CDN_URL}/new-theme/landing/home/testimonial_3.png`
export const TESTIMONIAL_4 = `${CDN_URL}/new-theme/landing/home/testimonial_4.png`

export const LOGO_BCG = `${CDN_URL}/new-theme/landing/home/logos/BCG-Logo_revised.svg`
export const LOGO_CELLTRION = `${CDN_URL}/new-theme/landing/home/logos/logo_celltrion_new.svg`

export const LOGO_HYUNDAI_GLO = `${CDN_URL}/new-theme/landing/home/logos/Hyundai_Glovis-Logo.svg`
export const LOGO_SK = `${CDN_URL}/new-theme/landing/home/logos/sk_logo_revised.svg`
export const LOGO_KRAFTON = `${CDN_URL}/new-theme/landing/home/logos/krafton-seeklogo.com.svg`
export const LOGO_DEV = `${CDN_URL}/new-theme/landing/home/logos/Devsisters-Logo.svg`
export const LOGO_EA = `${CDN_URL}/new-theme/landing/home/logos/logo_ea_new.svg`
export const LOGO_LG = `${CDN_URL}/new-theme/landing/home/logos/logo_lg_new.svg`

// 2022 summer Promotion

// 2022 Challenge
export const CHALLENGE_OG_IMG_EN = `${CDN_URL}/web/silicon-valley-challenge/og-image-en.png`
export const CHALLENGE_OG_IMG_KO = `${CDN_URL}/web/silicon-valley-challenge/og-image-ko.png`

// Landing Home video thumbnails
export const VIDEO_MATERIALS = `${CDN_URL}/new-theme/landing/home/videos/thumbnails/220518_plus_landing-materials-thumbnail_02.png`
export const VIDEO_INTRO = `${CDN_URL}/new-theme/landing/home/videos/thumbnails/220523-plus-landing_intro_thumbnails_new.png`
export const VIDEO_SYSTEM = `${CDN_URL}/new-theme/landing/home/videos/thumbnails/220523-plus-landing-system-thumbnails.png`
export const VIDEO_TUTOR = `${CDN_URL}/new-theme/landing/home/videos/thumbnails/220523_plus-landing-tutor_thumbnails.png`
export const TUTOR_LANDING_HOME_SEO_IMAGE = `${CDN_URL}/new-theme/tutor/landing_home_seo.png`

export const MOCK_SESSION_PASS_MODAL_IMAGE = `${CDN_URL}/new-theme/tutor/mocksession_pass_background.png`
export const ALL_SET_POPUP_IMAGE = `${CDN_URL}/new-theme/tutor/all-set-popup.png`
export const ALL_SET_TEENS_POPUP_IMAGE = `${CDN_URL}/new-theme/tutor/all-set-teens-popup.png`

//2022 TUTOR - SEOUL CHALLENGE

export const SEOUL_CHALLENGE_HERO = `${CDN_URL}/new-theme/tutor/seoul_challenge/seoul-challenge-hero.png`
export const WHY_SEOUL_CHALLENGE_1 = `${CDN_URL}/new-theme/tutor/seoul_challenge/reward_trip.svg`
export const WHY_SEOUL_CHALLENGE_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/reward_ipad2.svg`
export const WHY_SEOUL_CHALLENGE_3 = `${CDN_URL}/new-theme/tutor/seoul_challenge/reward_cash.svg`
export const WHY_SEOUL_CHALLENGE_4 = `${CDN_URL}/new-theme/tutor/seoul_challenge/reward_surprise.svg`

//2022 INVITE
export const IC_INVITE_PEOPLE = `${CDN_URL}/web/202208/people_40px.svg`
export const IC_INVITE_DOLLAR_COIN = `${CDN_URL}/web/202208/dollar_coin_40px.svg`

export const INVITE_EVENT_SECTION_1 = `${CDN_URL}/web/202208/event_section_1.png`
export const INVITE_EVENT_SECTION_2 = `${CDN_URL}/web/202208/event_section_2.png`
export const INVITE_EVENT_SECTION_3 = `${CDN_URL}/web/202208/event_section_3.png`
export const INVITE_EVENT_SECTION_4 = `${CDN_URL}/web/202208/event_section_4.png`
export const INVITE_EVENT_SECTION_5 = `${CDN_URL}/web/202208/event_section_5.png`
export const IC_FACEBOOK_LINK = `${CDN_URL}/web/202208/btn_facebook.svg`
export const IC_KAKAO_LINK = `${CDN_URL}/web/202208/btn_kakao.svg`
export const IC_COPY_LINK = `${CDN_URL}/web/202208/btn_copylink.svg`
export const SVG_ARROW_1 = `${CDN_URL}/web/202208/Arrow_1.svg`

export const TRIAL_EVENT_OG = `${CDN_URL}/web/202208/220810_trial_event_og_ko.png`
export const TRIAL_EVENT_KAKAO_OG = `${CDN_URL}/web/202208/220822_trial_og_kakao_ko.png`
export const IMG_INVITE_EVENT_OG = (locale) => `${CDN_URL}/web/202209/220810_invite_normal_OG_${locale}.png`
export const IMG_INVITE_EVENT_KAKAO_OG = (locale) => `${CDN_URL}/web/202209/220810_invite_normal_kakao_OG_${locale}.png`
// export const IMG_INVITE_EVENT_KAKAO_OG = (locale, number) =>
//   `${CDN_URL}/web/invite/202301/kakao-invite-${number}-${locale}.png`

//Seoul Challenge 22/09
//season1
export const ICON_AIRPLANE_STAMP_GRAY = `${CDN_URL}/new-theme/tutor/seoul_challenge/season1_stamp.png`

export const ICON_HOW_TO_PARTICIPATE_1 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_how_to_participate_1.png`
export const ICON_HOW_TO_PARTICIPATE_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_how_to_participate_2.png`
export const ICON_HOW_TO_PARTICIPATE_3 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_how_to_participate_3.png`
export const ICON_HOW_TO_PARTICIPATE_4 = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_how_to_participate_4.png`
export const ICON_TWENTY_DOLLAR = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_twenty_dollar.png`

export const ICON_AIRPLANE_PRIMARY = `${CDN_URL}/new-theme/tutor/seoul_challenge/icon_airplane_primary.png`
export const IMAGE_CHALLENGE_BOARD = `${CDN_URL}/new-theme/tutor/seoul_challenge/image_challenge_board.png`
export const WHAT_HAPPEN_IMAGE_4 = `${CDN_URL}/new-theme/tutor/seoul_challenge/what_happen_image_4.png`
export const WHAT_HAPPEN_IMAGE_3 = `${CDN_URL}/new-theme/tutor/seoul_challenge/what_happen_image_3.png`
export const WHAT_HAPPEN_IMAGE_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/what_happen_image_2.png`
export const WHAT_HAPPEN_IMAGE_1 = `${CDN_URL}/new-theme/tutor/seoul_challenge/what_happen_image_1.png`

//season 2

//2022 Welcome Coupon 8/29

//2022/08/26 BiteSizeFeedback Landing Page
export const BITE_SIZED_FEEDBACK_LANDING_HERO = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_landing_hero.png`
export const BITE_SIZED_FEEDBACK_LANDING_HERO_MOBILE = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_landing_hero_mobile.png`
export const BITE_SIZED_FEEDBACK_NEW_FEATURE = `${CDN_URL}/new-theme/bitesizefeedback/bite_sized_feedback_new_feature.png`
export const BITE_SIZED_FEEDBACK_FEATURE_1 = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_1_changed.png`
export const BITE_SIZED_FEEDBACK_FEATURE_2 = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_2.png`
export const BITE_SIZED_FEEDBACK_FEATURE_3 = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_3.png`
export const BITE_SIZED_FEEDBACK_FEATURE_4 = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_4.png`
export const BITE_SIZED_FEEDBACK_FEATURE_1_MOBILE = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_1_mobile_changed.png`
export const BITE_SIZED_FEEDBACK_FEATURE_2_MOBILE = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_2_mobile.png`
export const BITE_SIZED_FEEDBACK_FEATURE_3_MOBILE = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_3_mobile.png`
export const BITE_SIZED_FEEDBACK_FEATURE_4_MOBILE = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_4_mobile.png`
export const BITE_SIZED_FEEDBACK_STEP_1 = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_step_1.png`

export const BITE_SIZED_FEEDBACK_STEP_2_CHANGED = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_step_2_changed.png`
export const BITE_SIZED_FEEDBACK_STEP_3 = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_step_3.png`
export const BITE_SIZED_FEEDBACK_STEP_1_MOBILE = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_step_1_mobile.png`

export const BITE_SIZED_FEEDBACK_STEP_3_MOBILE = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_step_3_mobile.png`
export const BITE_SIZED_FEEDBACK_FLOATING_BUTTON = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_floating_button.png`
//en
export const BITE_SIZED_FEEDBACK_LANDING_HERO_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_landing_hero_en.png`
export const BITE_SIZED_FEEDBACK_LANDING_HERO_MOBILE_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_landing_hero_mobile_en.png`
export const BITE_SIZED_FEEDBACK_NEW_FEATURE_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_sized_feedback_new_feature_en.png`
export const BITE_SIZED_FEEDBACK_FEATURE_1_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_1_en.png`
export const BITE_SIZED_FEEDBACK_FEATURE_2_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_2_en.png`
export const BITE_SIZED_FEEDBACK_FEATURE_3_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_3_en.png`
export const BITE_SIZED_FEEDBACK_FEATURE_4_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_4_en.png`
export const BITE_SIZED_FEEDBACK_FEATURE_1_MOBILE_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_1_mobile_en.png`
export const BITE_SIZED_FEEDBACK_FEATURE_2_MOBILE_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_2_mobile_en.png`
export const BITE_SIZED_FEEDBACK_FEATURE_3_MOBILE_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_3_mobile_en.png`
export const BITE_SIZED_FEEDBACK_FEATURE_4_MOBILE_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_feature_4_mobile_en.png`
export const BITE_SIZED_FEEDBACK_STEP_1_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_step_1_en_changed.png`

export const BITE_SIZED_FEEDBACK_STEP_3_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_step_3_en.png`
export const BITE_SIZED_FEEDBACK_STEP_1_MOBILE_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_step_1_mobile_en_changed.png`

export const BITE_SIZED_FEEDBACK_STEP_3_MOBILE_EN = `${CDN_URL}/new-theme/bitesizefeedback/bite_size_feedback_step_3_mobile_en.png`

// Conference
export const CONFERENCE_HERO = `${CDN_URL}/web/202209/conference-hero-v2.png`
export const CONFERENCE_INTRO = `${CDN_URL}/web/202209/conference-intro.png`

export const CONFERENCE_TIMETABLE_1 = `${CDN_URL}/web/202209/timetable-day1.png`
export const CONFERENCE_TIMETABLE_2 = `${CDN_URL}/web/202209/timetable-day2.png`
export const CONFERENCE_FAQ = `${CDN_URL}/web/202209/conference-faq.png`
export const CONFERENCE_KAKAO_OG = `${CDN_URL}/web/202209/kakao-og.png`
export const CONFERENCE_NORMAL_OG = `${CDN_URL}/web/202209/normal-og.png`
export const CONFERENCE_DAY_1_1 = `${CDN_URL}/web/202209/day1_1.png`
export const CONFERENCE_DAY_1_2 = `${CDN_URL}/web/202209/day1_2.png`
export const CONFERENCE_DAY_1_3 = `${CDN_URL}/web/202209/day1_3.png`
export const CONFERENCE_DAY_1_4 = `${CDN_URL}/web/202209/day1_4.png`
export const CONFERENCE_DAY_1_5 = `${CDN_URL}/web/202209/day1_5.png`
export const CONFERENCE_DAY_2_1 = `${CDN_URL}/web/202209/day2_1.png`
export const CONFERENCE_DAY_2_2 = `${CDN_URL}/web/202209/day2_2.png`
export const CONFERENCE_DAY_2_3 = `${CDN_URL}/web/202209/day2_3.png`

// Pricing Hero

// Lesson Challenge
export const LESSON_CHALLENGE_COMPLETED = `${CDN_URL}/web/202209/completed.png`
export const LESSON_CHALLENGE_CONTINUOUS = `${CDN_URL}/web/202209/continuous.png`
export const LESSON_CHALLENGE_WITHIN_WEEK = `${CDN_URL}/web/202209/within_week.png`
export const LESSON_CHALLENGE_ACHIEVED = `${CDN_URL}/web/202209/achieved.png`

export const BOSTON_CHALLENGE_COMPLETED = (month) => `${CDN_URL}/web/challenge/2301/month_${month}.png`
export const LESSON_CHALLENGE_COMPLETED_OLD = `${CDN_URL}/web/202209/completed-old.png`
export const LESSON_CHALLENGE_CONTINUOUS_OLD = `${CDN_URL}/web/202209/continuous-old.png`
export const LESSON_CHALLENGE_WITHIN_WEEK_OLD = `${CDN_URL}/web/202209/within_week-old.png`
export const LESSON_CHALLENGE_ACHIEVED_OLD = `${CDN_URL}/web/202209/achieved-old.png`

export const LESSON_CHALLENGE_POPUP_COMPLETED = (locale) => `${CDN_URL}/web/202211/completed-stamp-${locale}.png`

export const LESSON_CHALLENGE_POPUP_WITHIN_WEEK = (locale) => `${CDN_URL}/web/202211/first-purchase-stamp-${locale}.png`
export const LESSON_CHALLENGE_POPUP_NORMAL = (locale) => `${CDN_URL}/web/202211/normal-stamp-${locale}.png`

//2022 Tutor Winter Referral Event - landing

export const WR_WEB_HERO = `${CDN_URL}/new-theme/tutor/winter_referral/winter_referral_web_hero.png`
export const ICON_COMPETITIVE_PAY = `${CDN_URL}/new-theme/tutor/winter_referral/icon_competitive_pay.png`
export const ICON_CONNECT_WITH_PROFESSIONALS = `${CDN_URL}/new-theme/tutor/winter_referral/icon_connect_with_professionals.png`
export const ICON_FLEXIBLE_SCHEDULE = `${CDN_URL}/new-theme/tutor/winter_referral/icon_flexible_schedule.png`
export const ICON_MINIMAL_PREP = `${CDN_URL}/new-theme/tutor/winter_referral/icon_minimal_prep.png`
export const IMG_LAST_SECTION_ILLUSTRATION = `${CDN_URL}/new-theme/tutor/winter_referral/img_last_section_illustration.png`
export const IMG_SECTION_2TFA = `${CDN_URL}/new-theme/tutor/winter_referral/img_section_2_tfa.png`

export const IMG_SECTION_3_BENEFIT_1 = `${CDN_URL}/new-theme/tutor/winter_referral/img_section3_benefit_1.png`
export const IMG_SECTION_3_BENEFIT_2 = `${CDN_URL}/new-theme/tutor/winter_referral/img_section3_benefit_2.png`
export const IMG_SECTION_3_BENEFIT_3 = `${CDN_URL}/new-theme/tutor/winter_referral/img_section3_benefit_3.png`
export const IMG_FOOTER = `${CDN_URL}/new-theme/tutor/winter_referral/footer.png`
export const IMG_REFERRAL_TUTOR_1 = `${CDN_URL}/new-theme/tutor/winter_referral/tutor_image_1.png`
export const IMG_REFERRAL_TUTOR_2 = `${CDN_URL}/new-theme/tutor/winter_referral/tutor_image_2.png`
export const IMG_REFERRAL_TUTOR_3 = `${CDN_URL}/new-theme/tutor/winter_referral/tutor_image_3.png`
export const IMG_REFERRAL_TUTOR_4 = `${CDN_URL}/new-theme/tutor/winter_referral/tutor_image_4.png`
export const IMAGE_AUTO_MATCHING_AVATAR_WEB = `${CDN_URL}/web/202210/ic-auto-matching.png`

export const PAID_WEBINAR_POPUP = (locale) => `${CDN_URL}/web/202211/webinar-popup-${locale}.png`

export const IMG_REFERRAL_STEP_1 = `${CDN_URL}/new-theme/tutor/winter_referral/step01_sign_with_a_resume_changed.png`
export const IMG_REFERRAL_STEP_2 = `${CDN_URL}/new-theme/tutor/winter_referral/step02_lead_a_mock_session.png`
export const IMG_REFERRAL_STEP_3 = `${CDN_URL}/new-theme/tutor/winter_referral/step03_complete_your_mock_session.png`
export const IMG_REFERRAL_STEP_4 = `${CDN_URL}/new-theme/tutor/winter_referral/step04_start_tutoring_changed.png`

//2022 Tutor Winter Rerral Event - portal
export const WINTER_REFERRAL_HERO = `${CDN_URL}/new-theme/tutor/winter_referral_portal/hero.png`
export const WINTER_REFERRAL_IMG_BENEFIT_1_CHANGE = `${CDN_URL}/new-theme/tutor/winter_referral_portal/image_benefit1_change2.png`
export const WINTER_REFERRAL_IMG_BENEFIT_2 = `${CDN_URL}/new-theme/tutor/winter_referral_portal/image_benefit2.png`

export const WINTER_REFERRAL_INSTAGRAM = `${CDN_URL}/new-theme/tutor/winter_referral_portal/instaram.png`
export const WINTER_REFERRAL_FACEBOOK = `${CDN_URL}/new-theme/tutor/winter_referral_portal/facebook.png`
export const WINTER_REFERRAL_LINKEDIN = `${CDN_URL}/new-theme/tutor/winter_referral_portal/linkedin.png`
export const WINTER_REFERRAL_COIN = `${CDN_URL}/new-theme/tutor/winter_referral_portal/coin.png`

export const WINTER_REFERRAL_BASKET_FILL = `${CDN_URL}/new-theme/tutor/winter_referral_portal/basket_fill.png`
export const WINTER_REFERRAL_BASKET_EMPTY = `${CDN_URL}/new-theme/tutor/winter_referral_portal/basket_empty.png`
export const WINTER_REFERRAL_BASKET_1 = `${CDN_URL}/new-theme/tutor/winter_referral_portal/basket_1.png`
export const WINTER_REFERRAL_BASKET_2 = `${CDN_URL}/new-theme/tutor/winter_referral_portal/basket_2.png`
export const WINTER_REFERRAL_BASKET_3 = `${CDN_URL}/new-theme/tutor/winter_referral_portal/basket_3.png`
export const WINTER_REFERRAL_BASKET_4 = `${CDN_URL}/new-theme/tutor/winter_referral_portal/basket_4.png`

export const WINTER_REFERRAL_SEO_IMAGE = `${CDN_URL}/new-theme/tutor/winter_referral_portal/winter_referral_poster_16_9.png`

//Seoul Challenge Season 4

export const SEASON4_COMPLETE = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/season4_complete.png`
export const SEASON4_STAMP_SUCCESS = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/season4_stamp__success.png`
export const SEASON4_STAMP_GRAY_MISSION_3 = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/season4_stamp_gray_mission_3.png`
export const SEASON4_STAMP_GRAY_MISSION = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/season4_stamp_gray_mission.png`
export const SEASON4_STAMP_GRAY_MISSION_2 = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/season4_stamp_gray_mission2.png`
export const SEASON4_STAMP_GRAY = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/season4_stamp_gray.png`
export const SEASON4_STAMP_REWARD = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/season4_stamp_reward.png`
export const SEASON4_STAMP = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/season4_stamp.png`
export const SEASON4_WK14_32PX_SUCCESS = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/stamp_wk14_32px_success.png`
export const SEASON4_WK14_32PX = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/stamp_wk14_32px.png`
export const SEASON4_WK14_40PX = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/stamp_wk14_40px.png`
export const SEASON4_WK15_32PX_SUCCESS = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/stamp_wk15_32px_success.png`
export const SEASON4_WK15_32PX = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/stamp_wk15_32px.png`
export const SEASON4_WK15_40PX = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/stamp_wk15_40px.png`
export const SEASON_FINISHED_BANNER = `${CDN_URL}/new-theme/tutor/seoul_challenge/season4/img_season4_banner_2x.png`
export const LAST_MIN_SCH_BANNER = `${CDN_URL}/new-theme/tutor/home_banner/unassigned_lesson.png`
export const FEEDBACK_REPORT_BANNER = `${CDN_URL}/new-theme/tutor/home_banner/feedback_report_web.png`
export const INCENTIVE_EVENT_BANNER = `${CDN_URL}/2024-image/1721184904627_banner-event_incentive.webp`
export const INCENTIVE_EVENT = `${CDN_URL}/2024-image/1721187646883_incentive_event.webp`

// TEENS Korean Landing Page

//2022 november engagement bonus event
export const ENGAGE_DOLLAR_COIN_10_SUCCESS = `${CDN_URL}/new-theme/tutor/engagement_event/dolar_coin_10_success.png`
export const ENGAGE_DOLLAR_COIN_10 = `${CDN_URL}/new-theme/tutor/engagement_event/dolar_coin_10.png`
export const ENGAGE_DOLLAR_COIN_20_SUCCESS = `${CDN_URL}/new-theme/tutor/engagement_event/dollar_coin_20_success.png`
export const ENGAGE_DOLLAR_COIN_20 = `${CDN_URL}/new-theme/tutor/engagement_event/dollar_coin_20.png`
export const ENGAGE_MISSION_2_STATUS_1 = `${CDN_URL}/new-theme/tutor/engagement_event/mission_2_status_1.png`
export const ENGAGE_MISSION_2_STATUS_2 = `${CDN_URL}/new-theme/tutor/engagement_event/mission_2_status_2.png`
export const ENGAGE_MISSION_2_STATUS_3 = `${CDN_URL}/new-theme/tutor/engagement_event/mission_2_status_3.png`
export const ENGAGE_MISSION_2_STATUS_4 = `${CDN_URL}/new-theme/tutor/engagement_event/mission_2_status_4.png`
export const ENGAGE_MISSION_2_STATUS_5 = `${CDN_URL}/new-theme/tutor/engagement_event/mission_2_status_5.png`
export const ENGAGE_MISSION_2_STATUS_SUCCESS_1 = `${CDN_URL}/new-theme/tutor/engagement_event/mission_2_status_success_1.png`
export const ENGAGE_MISSION_2_STATUS_SUCCESS_2 = `${CDN_URL}/new-theme/tutor/engagement_event/mission_2_status_success_2.png`
export const ENGAGE_MISSION_2_STATUS_SUCCESS_3 = `${CDN_URL}/new-theme/tutor/engagement_event/mission_2_status_success_3.png`
export const ENGAGE_MISSION_2_STATUS_SUCCESS_4 = `${CDN_URL}/new-theme/tutor/engagement_event/mission_2_status_success_4.png`
export const ENGAGE_MISSION_2_STATUS_SUCCESS_5 = `${CDN_URL}/new-theme/tutor/engagement_event/mission_2_status_success_5.png`
export const ENGAGE_ANDROID_CODE = `${CDN_URL}/new-theme/tutor/engagement_event/android_code.png`
export const ENGAGE_IOS_CODE = `${CDN_URL}/new-theme/tutor/engagement_event/ios_code.png`
export const ENGAGE_REPEAT_SCHEDULE = `${CDN_URL}/new-theme/tutor/engagement_event/repeat_schedule.png`
export const ENGAGE_PEAK_HOURS = `${CDN_URL}/new-theme/tutor/engagement_event/peak_hours.png`

//2023 01 05 tutor office open event OFFICE_EVENT_
export const OFFICE_EVENT_BANNER = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_home_banner_changed_2.png`
export const OFFICE_EVENT_BENEFIT_1 = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_benefit_1.png`
export const OFFICE_EVENT_BENEFIT_2 = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_benefit_2.png`
export const OFFICE_EVENT_BENEFIT_3 = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_benefit_3_chagned.png`
export const OFFICE_EVENT_FRIENDS = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_friends.png`
export const OFFICE_EVENT_GLOBE_IMAGE = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_globe_image.png`
export const OFFICE_EVENT_SCHOOL = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_school.png`
export const OFFICE_EVENT_SNEAKPEEK = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_sneak_peek.png`
export const OFFICE_EVENT_BENEFIT_BG = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_benefit_background.png`
export const OFFICE_EVENT_STEP_1 = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_step_1_changed_2.png`
export const OFFICE_EVENT_STEP_2 = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_step_2.png`
export const OFFICE_EVENT_STEP_3 = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_step_3_changed.png`
export const OFFICE_EVENT_STEP_4 = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_step_4_changed.png`
export const OFFICE_EVENT_SHARE_IMAGE = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_share_image.png`
export const OFFICE_EVENT_MORE_SNEAK_PEEK = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_more_sneak_peek_image.png`
export const OFFICE_EVENT_FACEBOOK_MESSENGER = `${CDN_URL}/new-theme/tutor/office_open_event/facebook_messenger.png`
export const OFFICE_EVENT_TWITTER = `${CDN_URL}/new-theme/tutor/office_open_event/twitter.png`

export const OFFICE_EVENT_LANDING_BENEFIT_1 = `${CDN_URL}/new-theme/tutor/office_open_event/img_benefit_1.png`
export const OFFICE_EVENT_LANDING_BENEFIT_2 = `${CDN_URL}/new-theme/tutor/office_open_event/img_benefit_2.png`
export const OFFICE_EVENT_LANDING_BENEFIT_3 = `${CDN_URL}/new-theme/tutor/office_open_event/img_benefit_3.png`
export const OFFICE_EVENT_50_DOLLARS = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_50_dollars.png`
export const OFFICE_EVENT_50_DOLLARS_SUCCESS = `${CDN_URL}/new-theme/tutor/office_open_event/Stamp_blank.png`
export const OFFICE_EVENT_TYPE = `${CDN_URL}/new-theme/tutor/office_open_event/office_event_type.png`
export const TUTOR_SCHOOL_DEFAULT = `${CDN_URL}/new-theme/tutor/tutor_school_default_image.png`

export const THANK_YOU_LETTER = `${CDN_URL}/new-theme/tutor/ringle_thank_you_letter.png`
export const THANK_YOU_LETTER_BANNER = `${CDN_URL}/new-theme/tutor/referral_thanks_letter.png`

// CAF Beta 2
export const IC_CAF_COMPLEXITY_BLACK = '/assets/img/icons/caf/icon-caf-complexity-black.svg'
export const IC_CAF_COMPLEXITY_SECONDARY = '/assets/img/icons/caf/icon-caf-complexity-secondary.svg'
export const IC_CAF_ACCURACY_BLACK = '/assets/img/icons/caf/icon-caf-accuracy-black.svg'
export const IC_CAF_ACCURACY_SECONDARY = '/assets/img/icons/caf/icon-caf-accuracy-secondary.svg'
export const IC_CAF_FLUENCY_BLACK = '/assets/img/icons/caf/icon-caf-fluency-black.svg'
export const IC_CAF_FLUENCY_SECONDARY = '/assets/img/icons/caf/icon-caf-fluency-secondary.svg'
export const IC_CAF_PINNED_ARROW = '/assets/img/icons/caf/icon-caf-pinned-arrow.svg'
export const IC_CAF_ERROR_CIRCLE = '/assets/img/icons/caf/icon-caf-error-circle.svg'
export const IC_CAF_ALERT_TRIANGLE = '/assets/img/icons/caf/icon-caf-alert-triangle.svg'
export const IC_CAF_MTLD_CONTENT = '/assets/img/icons/caf/icon-caf-mtld-content.svg'
export const IC_CAF_PIN_INACTIVE = '/assets/img/icons/caf/icon-caf-pin-inactive.svg'
export const IC_CAF_OVERALL_FIRE = '/assets/img/icons/caf/icon-caf-fire-primary.svg'
export const IC_CAF_SYMBOL_SECONDARY = '/assets/img/icons/caf/icon-caf-symbol.svg'
export const IC_CAF_LOCK_SECONDARY = '/assets/img/icons/caf/icon-lock-secondary.svg'
export const IC_CHECK_BLACK = '/assets/img/icons/caf/icon-check-black.svg'
export const IC_SORT_GRAY = '/assets/img/icons/caf/icon-sort-gray.svg'
export const IC_PIN_PRIMARY = '/assets/img/icons/caf/push-pin-active.svg'
export const IC_PIN_PRIMARY_HIGHLIGHT = '/assets/img/icons/caf/push-pin-active-highlight.svg'
export const IC_PIN_GRAY = '/assets/img/icons/caf/push-pin-inactive.svg'
export const IC_CAF_COMPLEXITY_LEVEL = '/assets/img/icons/caf/icon-caf-complexity-level.svg'
export const IC_CAF_ACCURACY_LEVEL = '/assets/img/icons/caf/icon-caf-accuracy-level.svg'
export const IC_CAF_FLUENCY_LEVEL = '/assets/img/icons/caf/icon-caf-fluency-level.svg'
export const IC_CAF_OVERVIEW_LEVEL = '/assets/img/icons/caf/icon-caf-overview-level.svg'
export const IC_CAF_MY_LEVEL = '/assets/img/icons/caf/icon-caf-my-level.svg'
export const NO_CAF_PLACEHOLDER = '/assets/img/icons/caf/caf-placeholder-statistics-page.jpg'

// Lesson Evaluation
export const LESSON_EVALUATION_STAR_GRAY = `${CDN_URL}/new-theme/student/lesson_evaluation/student_evaluation_gray.png`
export const LESSON_EVALUATION_STAR_YELLOW = `${CDN_URL}/new-theme/student/lesson_evaluation/student_evaluation_yellow.png`
export const LESSON_EVALUATION_ALARM_BLACK = `${CDN_URL}/new-theme/student/lesson_evaluation/student_evaluation_alarm_black.png`
export const LESSON_EVALUATION_TUTOR_BAD_GRAY = `${CDN_URL}/new-theme/student/lesson_evaluation/student_evaluation_tutor_bad_gray_1.png`
export const LESSON_EVALUATION_TUTOR_BAD_PRIMARY = `${CDN_URL}/new-theme/student/lesson_evaluation/student_evaluation_tutor_bad_primary.png`
export const LESSON_EVALUATION_TUTOR_GOOD_GRAY = `${CDN_URL}/new-theme/student/lesson_evaluation/student_evaluation_tutor_good_gray_1.png`
export const LESSON_EVALUATION_TUTOR_GOOD_PRIMARY = `${CDN_URL}/new-theme/student/lesson_evaluation/student_evaluation_tutor_good_primary.png`
export const LESSON_EVALUATION_TUTOR_SOSO_GRAY = `${CDN_URL}/new-theme/student/lesson_evaluation/student_evaluation_tutor_soso_gray_1.png`
export const LESSON_EVALUATION_TUTOR_SOSO_PRIMARY = `${CDN_URL}/new-theme/student/lesson_evaluation/student_evaluation_tutor_soso_primary.png`

//230308 webinar hero
export const WEBINAR_HERO_IMAGE_A = `${CDN_URL}/new-theme/student/webinar/webinar_hero_a_fix.png`
export const WEBINAR_HERO_IMAGE_B = `${CDN_URL}/new-theme/student/webinar/webinar_hero_b_fix.png`
export const WEBINAR_HERO_IMAGE_C = `${CDN_URL}/new-theme/student/webinar/webinar_here_c_fix.png`

export const BANNER_STOP = `${CDN_URL}/new-theme/student/banner-stop.png`
export const BANNER_PLAY = `${CDN_URL}/new-theme/student/banner-play.png`
export const ICON_PLUS_GRAY = `${CDN_URL}/new-theme/student/icon_plus_gray.png`
export const RECOMMENDED_TUTOR_BANNER = `${CDN_URL}/new-theme/student/recommended_tutor_banner.png`
export const RIGHT_ARROW = `${CDN_URL}/new-theme/student/right_arrow.png`
export const LEFT_ARROW = `${CDN_URL}/new-theme/student/left_arrow.png`
export const PLAY_BUTTON = `${CDN_URL}/new-theme/student/play_button.png`
